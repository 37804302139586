
import {
  defineComponent,
  ref,
  PropType,
  watch,
  computed,
  toRefs,
} from 'vue';

type Suggestion = { label: string; value: any };

export default defineComponent({
  name: 'Autocomplete',
  props: {
    initialSearch: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    onChangeSearchText: {
      type: Function as PropType<(e: string) => void>,
      isRequired: true,
    },
    className: {
      type: String,
      required: false,
    },
    suggestions: {
      type: Array as PropType<Array<Suggestion>>,
      required: true,
    },
    onSelect: {
      type: Function as PropType<(item: Suggestion) => any>,
      required: true,
    },
    resetOnFocusout: {
      // 마지막으로 선택한 값으로 돌릴 것인지
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { initialSearch } = toRefs(props);
    const isListVisible = ref(false);
    const search = ref<string | undefined>(initialSearch.value);
    const lastSelected = ref<Suggestion>();

    const list = ref(['a', 'b', 'c', 'd', 'a', 'b', 'c', 'd', 'a', 'b']);

    const handleFocusInput = () => {
      isListVisible.value = true;
    };

    const handleClickSuggestion = (item: Suggestion) => {
      isListVisible.value = false;
      search.value = item.label;
      lastSelected.value = item;
      props.onSelect(item);
    };

    const handleFocusOut = () => {
      isListVisible.value = false;

      if (props.resetOnFocusout && lastSelected.value) {
        search.value = lastSelected.value.label || '';
      }
    };

    const filteredList = computed(() => {
      if (!search.value) {
        return props.suggestions;
      } else {
        return props.suggestions.filter(
          (v) =>
            search.value &&
            v.label.toLowerCase().indexOf(search.value.toLowerCase()) > -1,
        );
      }
    });

    // 상위 컴폰너트에서 전달한 검색 input 값
    watch(initialSearch, (value) => {
      search.value = value;
    });

    // search 업데이트시 콜백 실행
    watch(search, (value) => {
      if (props.onChangeSearchText && value && value !== initialSearch.value) {
        props.onChangeSearchText(value);
      }
    });

    return {
      search,
      isListVisible,
      list,
      filteredList,
      handleFocusInput,
      handleClickSuggestion,
      handleFocusOut,
    };
  },
});
