<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7078 5.54297C10.7078 5.47422 10.6515 5.41797 10.5828 5.41797L9.55155 5.42266L7.99843 7.27422L6.44687 5.42422L5.41406 5.41953C5.34531 5.41953 5.28906 5.47422 5.28906 5.54453C5.28906 5.57422 5.3 5.60234 5.31875 5.62578L7.35155 8.04766L5.31875 10.468C5.29987 10.4909 5.28939 10.5195 5.28906 10.5492C5.28906 10.618 5.34531 10.6742 5.41406 10.6742L6.44687 10.6695L7.99843 8.81797L9.54998 10.668L10.5812 10.6727C10.65 10.6727 10.7062 10.618 10.7062 10.5477C10.7062 10.518 10.6953 10.4898 10.6765 10.4664L8.64686 8.04609L10.6797 5.62422C10.6984 5.60234 10.7078 5.57266 10.7078 5.54297Z"
      fill="#262626"
    />
    <path
      d="M8 1.01562C4.13438 1.01562 1 4.15 1 8.01562C1 11.8813 4.13438 15.0156 8 15.0156C11.8656 15.0156 15 11.8813 15 8.01562C15 4.15 11.8656 1.01562 8 1.01562ZM8 13.8281C4.79063 13.8281 2.1875 11.225 2.1875 8.01562C2.1875 4.80625 4.79063 2.20312 8 2.20312C11.2094 2.20312 13.8125 4.80625 13.8125 8.01562C13.8125 11.225 11.2094 13.8281 8 13.8281Z"
      fill="#262626"
    />
  </svg>
</template>
