<template>
  <div :class="['flex items-end border-b-3 border-black pb-4', className]">
    <span class="text-xl font-bold">{{ title }}</span>
    <span class="ml-9">{{ description }}</span>
  </div>
</template>

<script>
export default {
  props: {
    className: String,
    title: String,
    description: String,
  },
};
</script>

<style lang="scss" scoped></style>
