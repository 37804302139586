
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Popover',
  props: {
    description: String,
    className: String, // wrapper class
  },
});
