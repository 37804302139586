<template>
  <div :class="['RadioInput inline-flex items-center', className]">
    <input
      type="radio"
      :id="id"
      :name="name"
      :value="value"
      :checked="selected === value"
      @change="onChange"
    />
    <label :for="id" class="text-sm">{{ label }}</label>
    <div v-if="description" class="text-gray-600 text-xs ml-4">
      {{ description }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'RadioInput',
  props: {
    id: {
      type: String,
      isRequired: true,
    },
    name: {
      type: String,
      isRequired: true,
    },
    value: {
      type: String,
      isRequired: true,
    },
    label: {
      type: String,
      isRequired: true,
    },
    selected: {
      type: String,
      isRequired: true,
    },
    onChange: {
      type: Function as PropType<(e: Event) => void>,
      isRequired: true,
    },
    description: {
      type: String,
      isRequired: false,
    },
    className: {
      type: String,
      isRequired: false,
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../styles/mixins.scss';

.RadioInput {
  & > input {
    display: none;
  }

  & > input + label {
    position: relative;
    padding-left: 28px;

    &:before {
      @include centerY;
      left: 0;
      display: block;
      content: ' ';
      width: 20px;
      height: 20px;
      background-size: 20px;
      background-image: url('../assets/ico_radio_not_selected.svg');
    }

    &:hover {
      cursor: pointer;
    }
  }

  & > input:checked + label {
    &:before {
      background-image: url('../assets/ico_radio_selected.svg');
    }
  }
}
</style>
