
import { defineComponent, onMounted, ref, watch } from 'vue';
import _ from 'lodash';
import CategoryTitle from './CategoryTitle.vue';
import Input from '@/components/Input.vue';
import RadioInput from '@/components/RadioInput.vue';
import Checkbox from '@/components/Checkbox.vue';
import Select from '@/components/Select.vue';
import FormLabel from '@/components/FormLabel.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import CircleXIcon from '@/components/icons/CircleXIcon.vue';
import IconBase from '@/components/icons/IconBase.vue';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import datePattern from '@/constants/datePattern';
import router from '@/router';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import convertNumWithComma, { removeComma } from '@/utils/convertNumWithComma';
import useShippingTypeOptions from '@/mixins/useShippingTypeOptions';

export default defineComponent({
  name: 'Step2',
  components: {
    CategoryTitle,
    Input,
    RadioInput,
    Checkbox,
    Select,
    FormLabel,
    CheckCircleIcon,
    CircleXIcon,
    IconBase,
  },
  mixins: [scrollToTopOnMountMixin],
  props: {
    productIdQuery: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isAgreedTerms = ref(false);

    const DEFAULT_SELL_INFO = {
      enableBid: true,
      buynowOnly: false,
      buyLimit: false,
      makeOffer: false,
      buynowPrice: 0,
      enableBuyNow: false,
      startDate: null,
      endDate: null,
      feePrepaidShipping: false,
      shippingFee: 0,
      shippingTypeId: 1,
      startPrice: 0,
      taxFree: false,
      enableRegisterToken: false,
    }; // 폼 기본값
    const productData = ref<any>({
      ...DEFAULT_SELL_INFO,
    }); // 폼으로 제어할 상품정보
    const savedProductData = ref<any>({}); // 서버에서 가져온 상품정보

    const isBidDataRegistered = ref(false); // 판매정보 등록 여부는 판개기간이 등록되었는지로 확인한다.

    const getProductData = async () => {
      // 판매정보가 등록되어 있다면 데이터를 가져온다
      try {
        const res = await partnerAPI.product.getUsingGET5({
          productId: Number(props.productIdQuery),
        });

        const data = (res.data as any).data;

        savedProductData.value = data;
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      // step2에는 임시저장한 상품아이디가 필요하다.
      if (!props.productIdQuery) {
        alert('There is no product id');
        router.push({
          path: '/app/product/register',
          query: {
            step: 1,
          },
        });
      } else {
        getProductData();
      }
    });

    watch(savedProductData, (product) => {
      isBidDataRegistered.value = product.enableBid || product.buynowOnly;

      if (isBidDataRegistered.value) {
        // 판매정보가 등록되어 있으면
        productData.value = savedProductData.value;
      } else {
        // 등록되어 있지 않은 경우
        productData.value = {
          productId: parseInt(props.productIdQuery),
          ...DEFAULT_SELL_INFO,
          taxFree: savedProductData.value.categoryDepth1Id === 1, // art 카테고리 기본 면세 설정
        };
      }

      console.group('savedProductData');
      console.log('value', productData.value);
      console.log('isBidDataRegistered.value', isBidDataRegistered.value);
      console.groupEnd();
    });

    const handleChangeProductData = (field: string, value: any) => {
      productData.value[field] = value;
      console.group('handleChangeProductData');
      console.log('field.value', field, value);
      console.groupEnd();
    };

    const handleChangeDate = (field, value) => {
      try {
        const isoDate = new Date(value).toISOString();
        handleChangeProductData(field, isoDate);
      } catch (error) {
        console.error(error);
      }
    };

    /**
     * 판매설정 없이 상품 등록. step1에서 임시저장되었으므로 step3으로 직행
     */
    const handleClickRegisterWithoutInput = () => {
      router.push({
        path: '/app/product/register',
        query: {
          step: 3,
          productId: props.productIdQuery,
        },
      });
    };

    /**
     * 판매설정 완료
     * TODO: 유효성 검사 후 상품저장
     */
    const handleSubmitCompleteStep2 = _.throttle(async () => {
      if (!isAgreedTerms.value) {
        alert('상품판매에 대한 제반사항에 동의해야 합니다.');
        return;
      }
      const { enableBid, enableBuyNow, startPrice, buynowPrice } = productData.value;
      if (enableBid && (startPrice !== 0 && !startPrice)) {
        alert('시작가를 입력해 주세요.');
        productData.value.buynowPrice = null;
        return;
      }
      if (enableBid && enableBuyNow && buynowPrice <= startPrice) {
        alert('바이나우 금액은 시작가보다 높게 설정해야합니다.');
        productData.value.buynowPrice = null;
        return;
      }

      if (!isBidDataRegistered.value) {
        try {
          const submitData = {
            param: {
              ...productData.value,
            },
          };

          const { data } = await partnerAPI.product.createStep2UsingPOST(
            submitData
          );

          alert((data as any).message);

          //TODO: 자동으로 sale 상태가 되서, 임시처리 위 api 처리되면 삭제
          await partnerAPI.product.createUsingPUT({
            productId: parseInt(props.productIdQuery),
            salesStatus: 'invisible',
          });

          // step3로 이동.
          router.push({
            path: '/app/product/register',
            query: {
              step: 3,
              productId: props.productIdQuery,
            },
          });
        } catch (e) {
          console.error(e);
          alert(getServerErrorMessage(e));
        }
      } else {
        try {
          const submitData = {
            param: {
              ...productData.value,
            },
          };

          // NOTE: 상품 업데이트
          await partnerAPI.product.updateUsingPUT(submitData as any);

          alert('판매 정보가 수정되었습니다');
          // step3로 이동.
          router.push({
            path: '/app/product/register',
            query: {
              step: 3,
              productId: props.productIdQuery,
            },
          });
        } catch (e) {
          console.error(e);
          alert(getServerErrorMessage(e));
        }
      }
    }, 2000);

    const { shippingTypeOptions } = useShippingTypeOptions(false);

    const getDateTimeLocalValue = (date) => {
      return `${format(new Date(date), datePattern.INPUT_DATE)}T${format(
        new Date(date),
        datePattern.INPUT_TIME_24
      )}`;
    };

    const getNowDateString = () => {
      const today = new Date();
      return `${format(today, datePattern.INPUT_DATE)}T${format(today, datePattern.INPUT_TIME_24)}`
    };

    const validateDate = () => {
      const { startDate, endDate, enableBid } = productData.value;
      // 14일 이내 범위로 제한한다.
      if (enableBid && startDate && endDate) {
        const startTime = new Date(startDate).getTime() / 1000;
        const endTime = new Date(endDate).getTime() / 1000;

        if (startTime > endTime) {
          alert('판매 시작일이 먼저여야 합니다.');
          productData.value.endDate = null;
          return;
        }
        const TWO_WEEK_TIME = 60 * 60 * 24 * 14;
        if (endTime - startTime > TWO_WEEK_TIME) {
          alert('판매기간 범위는 14일 이내로 설정해야 합니다.');
          productData.value.endDate = new Date(
            (startTime + TWO_WEEK_TIME) * 1000
          ).toISOString();
        }
      }
    };

    return {
      productData,
      savedProductData,
      handleChangeProductData,
      handleChangeDate,
      isAgreedTerms,
      handleClickRegisterWithoutInput,
      handleSubmitCompleteStep2,
      format,
      datePattern,
      shippingTypeOptions,
      isBidDataRegistered,
      getDateTimeLocalValue,
      convertNumWithComma,
      removeComma,
      validateDate,
      getNowDateString,
    };
  },
});
