
import { computed, defineComponent, onMounted, ref } from 'vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import IconBase from '@/components/icons/IconBase.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import useShippingTypeOptions from '@/mixins/useShippingTypeOptions';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';
import VisibleIcon from '@/components/icons/VisibleIcon.vue';
import InvisibleIcon from '@/components/icons/InvisibleIcon.vue';

export default defineComponent({
  name: 'Step3',
  components: {
    IconBase,
    CheckCircleIcon,
    RemoveIcon,
    VisibleIcon,
    InvisibleIcon,
  },
  mixins: [scrollToTopOnMountMixin],
  props: {
    productIdQuery: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { categoryDepth1Obj, categoryDepth2Obj } = useCategoryOptions();
    const { shippingTypeOptions } = useShippingTypeOptions(false);
    const productDetail = ref<any>(null);
    const isInvisibled = computed(() =>
      productDetail.value.isInvisibled
    );

    // 상품을 변경할 수 [없는] 상태인가?
    const isCanNotChange = computed(() => {
      const { enableBid, startDate, salesStatus} = productDetail.value
      if (enableBid) {
        if (salesStatus === 'sale') {
          const now = new Date().getTime()
          const parsedStartDate = new Date(startDate).getTime()
          return now > parsedStartDate
        }
      }
      return false;
    })

    // 판매정보 설정했는지
    const isSetSalesInfo = computed(() => {
      const { enableBid, buynowOnly } = productDetail.value;
      return enableBid || buynowOnly;
    });
    const isConfirmSalesWarn = ref(false);

    onMounted(async () => {
      console.log('props.productIdQuery', props.productIdQuery);

      // step3에는 임시저장한 상품아이디가 필요하다.
      if (!props.productIdQuery) {
        alert('There is no product id');
        router.push({
          path: '/app/product/register',
          query: {
            step: 1,
          },
        });
      } else {
        // 저장된 상품정보를 가져온다.
        await fetchProduct()
      }
    });

    const fetchProduct = async () => {
      try {
        const { data } = await partnerAPI.product.getUsingGET5({
          productId: parseInt(props.productIdQuery),
        });

        console.log('data', data);
        productDetail.value = (data as any).data;
      } catch (error) {
        console.error(error);
      }
    }

    const gotoRegisterStep = (step: number) => {
      if (isCanNotChange.value) {
        alert('판매등록이 완료된 상품은 수정이 불가능합니다.');
        return;
      }
      if (productDetail.value) {
        router.push({
          path: '/app/product/register',
          query: {
            step,
            productId: props.productIdQuery,
          },
        });
      }
    };

    const handleClickRegister = async () => {
      if (isCanNotChange.value) {
        alert('이미 판매등록이 완료되었습니다.');
        return;
      }
      if (!isSetSalesInfo.value) {
        alert('판매정보를 먼저 설정하셔야 합니다.');
        return;
      }
      if (!isConfirmSalesWarn.value) {
        if (confirm('상품이 등록되면 수정할 수 없습니다.')) {
          isConfirmSalesWarn.value = true;
        }
        return;
      }
      try {
        // 상품등록 (상태변경) 이름 변경 예정
        const { data } = await partnerAPI.product.createUsingPUT({
          productId: parseInt(props.productIdQuery),
          salesStatus: 'sale',
        });

        console.log('data', data);
        alert('판매등록 완료됐습니다.');

        router.push('/app/product');
      } catch (e) {
        console.error(e);
        alert(getServerErrorMessage(e));
      }
    };

    const handleClickDelete = async () => {
      if (isCanNotChange.value) {
        alert('판매등록이 완료된 상품은 삭제가 불가능합니다.');
        return;
      }
      if (confirm('상품 삭제시 복원이 불가능합니다.\n' +
          '정말로 삭제하시겠습니까?')) {
        try {
          const { data } = await partnerAPI.product.deleteUsingDELETE({
            productId: parseInt(props.productIdQuery),
          });
          alert((data as any).message);
          router.push('/app/product');
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      }
    };

    const handleToggleVisible = async () => {
      if (isCanNotChange.value) {
        alert('판매중인 상품은 변경할 수 없습니다.')
        return;
      }
      // 상품보일경우
      if(!isInvisibled.value) {
        try {
          //숨기기
          await partnerAPI.product.hideUsingPUT({
            productId: parseInt(props.productIdQuery)
          })
          alert('상품이 숨김처리 되었습니다.');
          await fetchProduct();
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      } else {
        try {
          //보이기
          await partnerAPI.product.showUsingPUT({
            productId: parseInt(props.productIdQuery)
          })
          alert('상품이 공개처리 되었습니다.');
          await fetchProduct();
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      }
    }

    const formatDate = (date: string) => {
      console.log('date', date);
      return format(new Date(date), 'yyyy/MM/dd HH:mm');
    };

    return {
      isInvisibled,
      productDetail,
      gotoRegisterStep,
      handleClickRegister,
      handleClickDelete,
      formatDate,
      handleToggleVisible,
      isCanNotChange,
      isConfirmSalesWarn,
      isSetSalesInfo,
      categoryDepth1Obj,
      categoryDepth2Obj,
      shippingTypeOptions,
    };
  },
});
