
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Checkbox',
  props: {
    classes: {
      type: Object as PropType<{ input: string }>,
      toBeRequired: false,
    },
    checked: Boolean,
    onChange: Function as PropType<() => void>,
    name: {
      type: String,
      isRequired: false,
    },
    disabled: {
      type: Boolean,
      isRequired: false,
    },
    label: {
      type: String,
      isRequired: false,
    },
    description: {
      type: String,
      isRequired: false,
    },
    className: {
      type: String,
      isRequired: false,
    },
  },
});
