import partnerAPI from '@/service/partnerAPI';
import { computed, onMounted, ref } from 'vue';

export default function useStatusOptions() {
  const statusOptions = ref<{ label: any; value: any }>([] as any);
  const statusOptionsForSearch = computed(() =>
    [{ label: '전체', value: '' }].concat(statusOptions.value)
  );

  onMounted(() => {
    partnerAPI.partnerProduct
      .optionListUsingGET1({
        type: 'status',
      })
      .then(({ data }) => {
        const options = (data as any).data.map((v: any) => ({
          label: v.kor,
          value: v.kor,
        }));

        statusOptions.value = options;
      });
  });

  return {
    statusOptions,
    statusOptionsForSearch,
  };
}
