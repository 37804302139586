<template>
  <div
    :class="[
      'FormInput relative h-10 border inline-flex px-4 text-sm',
      disabled ? 'border-disabled bg-gray-200' : 'border-black',
      className,
    ]"
  >
    <input
      :max="max"
      :min="min"
      :disabled="disabled"
      :type="type || 'text'"
      :placeholder="placeholder"
      :value="value"
      :maxlength="maxlength"
      :class="[
        'w-full focus:outline-none placeholder-gray-400 disabled:bg-non bg-white ',
        disabled ? ' text-disabled bg-gray-200' : 'text-black',
      ]"
      @change="onChange"
      @keyup="onKeyup"
      @blur="onBlur"
      :style="{
        textAlign: textAlign || 'left',
      }"
    />
    <span v-if="unit" class="pl-1 inline-flex items-center"> {{ unit }} </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Input',
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    maxlength: {
      type: String,
      required: false,
    },
    onChange: {
      type: Function as PropType<() => void>,
      isRequired: false,
    },
    onKeyup: {
      type: Function as PropType<() => void>,
      isRequired: false,
    },
    onBlur: {
      type: Function as PropType<() => void>,
      isRequired: false,
    },
    popoverDescription: {
      // "?" 아이콘 위에 올리면 팝오버 표시
      type: String,
      required: false,
    },
    textAlign: {
      type: String,
      required: false,
    },
    unit: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    min: {
      type: String,
      required: false,
    },
    max: {
      type: String,
      required: false,
    },
  },
  methods: {},
});
</script>

<style lang="scss" scoped>
.FormInput {
  & > input[type='date'] {
    background-image: url('../assets/ico_calendar.svg') no-repeat;
    background-size: 16px;
    background-position: calc(100% - 14px) center;

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }
}
</style>
