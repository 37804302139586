<template>
  <div
    :class="[
      'relative h-10 border border-black inline-flex text-sm bg-white',
      className,
    ]"
  >
    <input
      type="text"
      v-model="search"
      :placeholder="placeholder"
      class="w-full focus:outline-none placeholder-gray-400 px-4"
      @focus="isListVisible = true"
    />

    <div
      v-if="isListVisible && filteredList.length > 0"
      class="
        absolute
        z-20
        top-full
        w-full
        l-0
        r-0
        bg-white
        max-h-60
        overflow-y-scroll
        border border-black border-l border-b border-r
      "
      @focusout="handleFocusOut"
    >
      <div
        v-for="(item, index) in filteredList"
        :key="index"
        class="px-4 py-1 hover:bg-gray-100 cursor-pointer"
        @click.stop="handleClickSuggestion(item)"
      >
        {{ item.label }}
      </div>
    </div>
    <div
      v-if="isListVisible"
      class="fixed inset-0 z-10"
      @click="handleFocusOut"
    ></div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  PropType,
  watch,
  computed,
  toRefs,
} from 'vue';

type Suggestion = { label: string; value: any };

export default defineComponent({
  name: 'Autocomplete',
  props: {
    initialSearch: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    onChangeSearchText: {
      type: Function as PropType<(e: string) => void>,
      isRequired: true,
    },
    className: {
      type: String,
      required: false,
    },
    suggestions: {
      type: Array as PropType<Array<Suggestion>>,
      required: true,
    },
    onSelect: {
      type: Function as PropType<(item: Suggestion) => any>,
      required: true,
    },
    resetOnFocusout: {
      // 마지막으로 선택한 값으로 돌릴 것인지
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { initialSearch } = toRefs(props);
    const isListVisible = ref(false);
    const search = ref<string | undefined>(initialSearch.value);
    const lastSelected = ref<Suggestion>();

    const list = ref(['a', 'b', 'c', 'd', 'a', 'b', 'c', 'd', 'a', 'b']);

    const handleFocusInput = () => {
      isListVisible.value = true;
    };

    const handleClickSuggestion = (item: Suggestion) => {
      isListVisible.value = false;
      search.value = item.label;
      lastSelected.value = item;
      props.onSelect(item);
    };

    const handleFocusOut = () => {
      isListVisible.value = false;

      if (props.resetOnFocusout && lastSelected.value) {
        search.value = lastSelected.value.label || '';
      }
    };

    const filteredList = computed(() => {
      if (!search.value) {
        return props.suggestions;
      } else {
        return props.suggestions.filter(
          (v) =>
            search.value &&
            v.label.toLowerCase().indexOf(search.value.toLowerCase()) > -1,
        );
      }
    });

    // 상위 컴폰너트에서 전달한 검색 input 값
    watch(initialSearch, (value) => {
      search.value = value;
    });

    // search 업데이트시 콜백 실행
    watch(search, (value) => {
      if (props.onChangeSearchText && value && value !== initialSearch.value) {
        props.onChangeSearchText(value);
      }
    });

    return {
      search,
      isListVisible,
      list,
      filteredList,
      handleFocusInput,
      handleClickSuggestion,
      handleFocusOut,
    };
  },
});
</script>

<style lang="scss" scoped></style>
