
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'RadioInput',
  props: {
    id: {
      type: String,
      isRequired: true,
    },
    name: {
      type: String,
      isRequired: true,
    },
    value: {
      type: String,
      isRequired: true,
    },
    label: {
      type: String,
      isRequired: true,
    },
    selected: {
      type: String,
      isRequired: true,
    },
    onChange: {
      type: Function as PropType<(e: Event) => void>,
      isRequired: true,
    },
    description: {
      type: String,
      isRequired: false,
    },
    className: {
      type: String,
      isRequired: false,
    },
  },
});
