
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CurrentStep',
  props: {
    step: Number, // 1부터 시작
    isActive: Boolean,
  },
  data() {
    return {
      stepList: [
        {
          name: '상품정보 입력',
          description: '상품정보와 이미지를 등록해주세요',
        },
        {
          name: '판매설정',
          description: '판매옵션을 설정해주세요.',
        },
        {
          name: '설정확인',
          description: '등록된 정보를 확인해주세요.',
        },
      ],
    };
  },
});
