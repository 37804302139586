
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Input',
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    maxlength: {
      type: String,
      required: false,
    },
    onChange: {
      type: Function as PropType<() => void>,
      isRequired: false,
    },
    onKeyup: {
      type: Function as PropType<() => void>,
      isRequired: false,
    },
    onBlur: {
      type: Function as PropType<() => void>,
      isRequired: false,
    },
    popoverDescription: {
      // "?" 아이콘 위에 올리면 팝오버 표시
      type: String,
      required: false,
    },
    textAlign: {
      type: String,
      required: false,
    },
    unit: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    min: {
      type: String,
      required: false,
    },
    max: {
      type: String,
      required: false,
    },
  },
  methods: {},
});
