<template>
  <div :class="['PopoverWrapper', className]">
    <slot></slot>

    <div class="PopoverContents">
      {{ description }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Popover',
  props: {
    description: String,
    className: String, // wrapper class
  },
});
</script>

<style lang="scss" scoped>
@import '../styles/mixins.scss';

.PopoverWrapper {
  position: relative;
  .PopoverContents {
    display: none;
    position: absolute;
    z-index: 1;
    top: calc(100% + 0.5rem);
    left: 0;
    min-width: 300px;
    font-size: 14px;
    padding: 10px;
    background: #171717;
    color: #fff;
    text-align: left;

    /* TODO:/ */

    &::before {
      position: absolute;
      display: block;
      content: ' ';
      z-index: 2;
      top: -6px;
      left: 0;
      @include triangleUp(5px, 6px, #171717);
    }
  }

  &:hover {
    .PopoverContents {
      display: block;
    }
  }
}
</style>
