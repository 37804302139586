
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  ref,
  watch,
} from 'vue';
import Container from '@/components/Container.vue';
import RegisterStepItem from './RegisterStepItem.vue';
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import router from '@/router';

// [Dynamic & Async Component](https://v3.vuejs.org/guide/component-dynamic-async.html#dynamic-async-components)
export default defineComponent({
  name: 'Product',
  components: {
    Container,
    RegisterStepItem,
    Step1,
    Step2,
    Step3,
  },
  setup() {
    const stepQuery = ref<string | undefined>(undefined);
    const productIdQuery = ref<string | undefined>(undefined);

    const updateQueryInfo = () => {
      stepQuery.value = router.currentRoute.value.query.step?.toString() || '1';
      productIdQuery.value =
        router.currentRoute.value.query.productId?.toString();
    };

    onMounted(() => {
      updateQueryInfo();
    });

    onUpdated(() => {
      updateQueryInfo();
    });

    return {
      stepQuery,
      productIdQuery,
    };
  },
});
